import React from 'react'
import { Modal } from 'semantic-ui-react'
import LineOfficerIcon from '../../../icons/LineOfficerRedirect'
import { theme as ahaTheme } from '@aimhigh/theme'
import { Button, FormTextField } from '@aimhigh/web-components/web'
import { emailValidation, LINE_OFFICER_ICON_SIZE, phoneValidation, textFieldValidation } from '../lineOfficer.constants'
import { useForm } from 'react-hook-form'
import { ActionsWrapper, ContentWrapper, HeaderWrapper, IconWrapper, ModalForm } from './LineOfficerModal.styles'

const MODAL_CONFIGS = {
  enlisted: {
    title: 'Oops! Wrong Place',
    description: `It seems you're currently Active Duty. Please head over to the E2O page for information and guidance specific to your situation.`,
    actions: {
      redirect: {
        label: 'Redirect',
        href: 'https://www.recruiting.af.mil/About-Us/Line-Officer-Candidate-Information-and-Resources/',
        variant: 'primary',
        order: 1,
      },
      dismiss: {
        label: 'Dismiss',
        variant: 'secondary',
        onClick: 'close',
        order: 2,
      },
    },
  },
  age: {
    title: 'Ineligible to Apply',
    description: `Unfortunately, you do not meet the age requirement to apply. 
                  We encourage you to explore opportunities with other organizations that may be a better fit.`,
    actions: {
      civilAirPatrol: {
        label: 'Join Civil Air Patrol',
        href: 'https://www.gocivilairpatrol.com/',
        variant: 'primary',
        order: 1,
      },
      civilianCareers: {
        label: 'Civilian Careers',
        href: 'https://afciviliancareers.com/',
        variant: 'primary',
        order: 2,
      },
      dismiss: {
        label: 'Dismiss',
        variant: 'secondary',
        onClick: 'close',
        order: 3,
      },
    },
  },
  education: {
    title: 'Oops! Wrong Place',
    description: `This path isn't a match for you. Consider other opportunities like the Guard, Reserve, or Enlisted options.`,
    actions: {
      activeDuty: {
        label: 'Join Active Duty',
        href: 'https://www.airforce.com/ways-to-serve/air-force-active-duty',
        variant: 'primary',
        order: 1,
      },
      guard: {
        label: 'Join the Guard',
        href: 'https://www.airforce.com/ways-to-serve/air-national-guard',
        variant: 'primary',
        order: 2,
      },
      reserves: {
        label: 'Join the Reserves',
        href: 'https://www.airforce.com/ways-to-serve/air-force-reserve',
        variant: 'primary',
        order: 3,
      },
      dismiss: {
        label: 'Dismiss',
        variant: 'secondary',
        onClick: 'close',
        order: 4,
      },
    },
  },
  recruiter: {
    title: 'Work with a recruiter',
    description: `To continue, you'll need to work with a recruiter. 
                  Please provide your information below, and we'll send your details to get you started.`,
    actions: {
      submit: {
        label: 'Send',
        variant: 'primary',
        order: 1,
      },
      dismiss: {
        label: 'Dismiss',
        variant: 'secondary',
        onClick: 'close',
        order: 2,
      },
    },
  },
}

const LineOfficerModal = ({ isOpen, onClose, type }) => {
  const recruiterForm = useForm({
    mode: 'onBlur',
  })

  const modalConfig = MODAL_CONFIGS[type] || {}
  const { title, description, actions = {} } = modalConfig

  const renderActionButton = ({ key, label, href, variant, onClick }) => {
    const buttonContent = (
      <Button
        variant={ variant }
        style={ { width: '100%' } }
        onClick={ onClick === 'close' ? onClose : onClick }>
        { label }
      </Button>
    )

    if (href) {
      return (
        <a
          key={ key }
          href={ href }
          target='_blank'
          rel='noopener noreferrer'
          style={ { textDecoration: 'none', flex: 1 } }>
          { buttonContent }
        </a>
      )
    }

    return (
      <div key={ key } style={ { flex: 1 } }>
        { buttonContent }
      </div>
    )
  }

  const renderActions = () => {
    if (type === 'recruiter') {
      return (
        <ActionsWrapper>
          <ModalForm formHooks={ recruiterForm } onSubmit={ onClose }>
            <FormTextField label='First Name' name='first_name' rules={ textFieldValidation } style={ { gridColumn: 'span 1' } } />
            <FormTextField label='Last Name' name='last_name' rules={ textFieldValidation } style={ { gridColumn: 'span 1' } } />
            <FormTextField label='Email Address' name='email' rules={ emailValidation } />
            <FormTextField label='Phone Number' name='phone' rules={ phoneValidation } placeholder='XXX-XXX-XXXX' />
            <ActionsWrapper style={ { flexDirection: 'row' } }>
              <Button variant='secondary' onClick={ onClose }>{ actions.dismiss.label }</Button>
              <Button type='submit'>{ actions.submit.label }</Button>
            </ActionsWrapper>
          </ModalForm>
        </ActionsWrapper>
      )
    }

    let actionList = Object.entries(actions)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([key, config]) => ({
        key,
        ...config,
      }))

    if (type === 'enlisted') {
      actionList = actionList.reverse()
    }

    return (
      <ActionsWrapper style={ { flexDirection: actionList.length <= 2 ? 'row' : 'column' } }>
        { actionList.map(renderActionButton) }
      </ActionsWrapper>
    )
  }

  return (
    <Modal open={ isOpen } size='mini' onClose={ onClose } closeIcon>
      <Modal.Header>
        <HeaderWrapper>
          { title }
        </HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <ContentWrapper>
          <IconWrapper>
            <LineOfficerIcon size={ LINE_OFFICER_ICON_SIZE } color={ ahaTheme.colors.brand.brandDark } />
          </IconWrapper>
          { description }
        </ContentWrapper>
      </Modal.Content>
      <Modal.Actions style={ { border: 'none', background: ahaTheme.colors.background.level0, paddingTop: 0 } }>
        { renderActions() }
      </Modal.Actions>
    </Modal>
  )
}

export default LineOfficerModal
