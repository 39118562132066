import { theme as ahaTheme } from '@aimhigh/theme'
import { emailRegex } from '../../forms/validation'

export const SVG_LOGO_HEADER_SIZE = 144

export const HELP_ICON_SIZE = 14

export const LINE_OFFICER_ICON_SIZE = 64

export const HEADER_HEIGHT = 160

export const FOOTER_HEIGHT = 20 + (ahaTheme.spacing.l * 2)

export const requiredValidation = {
  required: 'Field is required',
}

export const RADIO_VALUES = {
  YES: 1,
  NO: 0,
}

export const textFieldValidation = {
  ...requiredValidation,
  minLength: {
    value: 2,
    message: `Field must be more than 2 characters`,
  },
}

export const gpaValidation = {
  ...requiredValidation,
  min: {
    value: 0,
    message: 'GPA must be between 0.0 and 4.0',
  },
  max: {
    value: 4,
    message: 'GPA must be between 0.0 and 4.0',
  },
}

export const emailValidation = {
  ...requiredValidation,
  pattern: {
    value: emailRegex,
    message: `Invalid email format`,
  },
}

export const passwordValidation = {
  ...requiredValidation,
  minLength: {
    value: 8,
    message: `Password must be at least 8 characters long`,
  },
  validate: value => {
    if (!/[a-z]/u.test(value) ||
    !/[A-Z]/u.test(value) ||
    !/[0-9]/u.test(value) ||
    !/[!@#$%^]/u.test(value)
    ) {
      return 'Password must contain at least one uppercase letter, lowercase letter, number, and special character'
    }

    return true
  },
}

export const phoneValidation = {
  required: `Field is required`,
  validate: val => {
    if (val.replace(/\D/gu, '').length !== 10) {
      return `Phone Number must contain 10 digits`
    }

    return true
  },
}

export const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export const jobCategoryOptions = [
  { label: 'Dentist', value: 'dentist' },
  { label: 'Pharmacist', value: 'pharmacist' },
  { label: 'Audiologist', value: 'audiologist' },
  { label: 'Social Worker', value: 'social_worker' },
  { label: 'Surgeon', value: 'surgeon' },
  { label: 'Nurse', value: 'nurse' },
  { label: 'Nurse Practitioner', value: 'nurse_practitioner' },
  { label: 'Physician', value: 'physician' },
  { label: 'Pathologist', value: 'pathologist' },
  { label: 'Dental Assistant', value: 'dental_assistant' },
  { label: 'Health Services Management', value: 'health_services_management' },
  { label: 'Optometrist', value: 'optometrist' },
  { label: 'Medical Healthcare Provider - Other', value: 'medical_healthcare_provider_other' },
  { label: 'Chaplain', value: 'chaplain' },
  { label: 'JAG (Judge Advocate General)', value: 'jag_judge_advocate_general' },
  { label: 'Paralegal', value: 'paralegal' },
  { label: 'Electrical Engineer', value: 'electrical_engineer' },
  { label: 'Civil Engineer', value: 'civil_engineer' },
  { label: 'Mechanical Engineer', value: 'mechanical_engineer' },
  { label: 'Nuclear Engineer', value: 'nuclear_engineer' },
  { label: 'Engineer (Other)', value: 'engineer_other' },
  { label: 'Aircraft Maintenance', value: 'aircraft_maintenance' },
  { label: 'Helicopter Pilot', value: 'helicopter_pilot' },
  { label: 'Airplane Pilot', value: 'airplane_pilot' },
  { label: 'Biomedical Equipment', value: 'biomedical_equipment' },
  { label: 'Meteorologist', value: 'meteorologist' },
  { label: 'Chemist', value: 'chemist' },
  { label: 'Missile & Space Systems', value: 'missile_space_systems' },
  { label: 'Flight Engineer', value: 'flight_engineer' },
  { label: 'Flight Attendant', value: 'flight_attendant' },
  { label: 'Air Traffic Control', value: 'air_traffic_control' },
  { label: 'Weather', value: 'weather' },
  { label: 'Avionics', value: 'avionics' },
  { label: 'Public Affairs', value: 'public_affairs' },
  { label: 'Band', value: 'band' },
  { label: 'Fuels', value: 'fuels' },
  { label: 'Air Force Transportation', value: 'air_force_transportation' },
  { label: 'Resource Administration', value: 'resource_administration' },
  { label: 'Personnel or Operations Management', value: 'personnel_or_operations_management' },
  { label: 'Operations Intelligence', value: 'operations_intelligence' },
  { label: 'Cyberspace Operations', value: 'cyberspace_operations' },
  { label: 'Intelligence', value: 'intelligence' },
  { label: 'Linguist', value: 'linguist' },
  { label: 'Combat Control', value: 'combat_control' },
  { label: 'Pararescue or Reconnaissance', value: 'pararescue_or_reconnaissance' },
  { label: 'Air Battle Management', value: 'air_battle_management' },
  { label: 'Special Missions', value: 'special_missions' },
  { label: 'Airfield Management', value: 'airfield_management' },
  { label: 'Fire Protection', value: 'fire_protection' },
  { label: 'Security Forces', value: 'security_forces' },
]

export const serviceTypeOptions = [
  { label: 'Full-Time', value: 'full_time' },
  { label: 'Part-Time', value: 'part_time' },
]

export const highestDegreeOptions = [
  { label: 'Current High School Student', value: 'current_high_school_student' },
  { label: 'High School graduate or GED', value: 'high_school_graduate_or_ged' },
  { label: `Associate's`, value: 'associates' },
  { label: `Bachelor's Degree (currently enrolled)`, value: 'bachelors_degree_currently_enrolled' },
  { label: `Bachelor's Degree`, value: 'bachelors_degree' },
  { label: `Master's Degree`, value: 'masters_degree' },
  { label: 'Doctorate', value: 'doctorate' },
  { label: 'Professional Degree (e.g., MD, DVM, JD)', value: 'professional_degree_e_g_md_dvm_jd' },
]

export const majorOptions = [
  { label: 'ADULT HEALTH NURSING', value: 'adult_health_nursing' },
  { label: 'AEROSPACE ENGINEERING OTHER', value: 'aerospace_engineering_other' },
  { label: 'Abrasives', value: 'abrasives' },
  { label: 'Accounting', value: 'accounting' },
  { label: 'Budgeting', value: 'budgeting' },
  { label: 'Italian', value: 'italian' },
  { label: 'Indian History', value: 'indian_history' },
  { label: 'MED-TECH OTHER', value: 'med_tech_other' },
  { label: 'Meteorology', value: 'meteorology' },
  { label: 'Nursing', value: 'nursing' },
  { label: 'Occupational Therapy', value: 'occupational_therapy' },
  { label: 'Propulsion', value: 'propulsion' },
  { label: 'Sales', value: 'sales' },
  { label: 'Other', value: 'other' },
]

export const mockSteps = [
  { label: 'Demographics', duration: 1, complete: true, href: '#' },
  { label: 'Contact Information', duration: 3, complete: false, href: '#' },
  { label: 'Eligibility', duration: 65, complete: false, href: '#' },
  { label: 'Select Careers', duration: 100, complete: false, href: '#' },
  { label: 'Upload Documents', duration: 17, complete: false, href: '#' },
]

export const mockTimelineItems = [
  'Registration',
  'Application Overview',
  'Initial Application',
  'Register in AFCEP',
  'Sign Air Force Forms',
  'Application Tests',
  'Military Entrance Processing Station',
  'Commander Interview',
  'Finalize Application',
  'Accept',
  'Tasks',
  'EAD Briefing',
]

export const LineOfficerApplicationForms = {
  Demographics: 'Demographics',
  ContactInfo: 'ContactInfo',
  Eligibility: 'Eligibility',
  Education: 'Education',
  Careers: 'Careers',
  AFOQTTest: 'AFOQTTest',
  PilotTest: 'PilotTest',
  PriorService: 'PriorService',
  UploadDocuments: 'Upload Documents',
  Overview: 'Overview',
}

export const lineOfficerFormTitles = {
  [LineOfficerApplicationForms.Demographics]: 'Demographics',
  [LineOfficerApplicationForms.ContactInfo]: 'Contact Information',
  [LineOfficerApplicationForms.Eligibility]: 'Eligibility',
  [LineOfficerApplicationForms.Education]: 'Education',
  [LineOfficerApplicationForms.Careers]: 'Select Careers',
  [LineOfficerApplicationForms.AFOQTTest]: 'AFOQT TEST',
  [LineOfficerApplicationForms.PilotTest]: 'Pilot Test',
  [LineOfficerApplicationForms.PriorService]: 'Prior Service',
  [LineOfficerApplicationForms.UploadDocuments]: 'Upload Documents',
  [LineOfficerApplicationForms.Overview]: 'Application Overview',
}

export const lineOfficerFormDescriptions = {
  [LineOfficerApplicationForms.Demographics]: 'This form gathers key demographic details to assess your eligibility for service. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.ContactInfo]: 'This form collects your contact details to ensure your eligibility for service. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.Eligibility]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.Education]: 'This form collects your education details to assess your qualifications. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.Careers]: 'Select and rank up to 20 career options based on your preferences. You will be assigned based on your choices and its availability.',
  [LineOfficerApplicationForms.AFOQTTest]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.PilotTest]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.PriorService]: 'This form collects details about your prior military service. Please fill out all sections accurately.',
  [LineOfficerApplicationForms.UploadDocuments]: 'Please upload the required documents to complete your application. Ensure all files are clear and accurate.',
  [LineOfficerApplicationForms.Overview]: 'This form collects information to determine your eligibility for the program. Please fill out all sections accurately.',
}
