import styled from '@emotion/styled'
import { Form } from '@aimhigh/web-components/web'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  width: 100px;

  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background.level1};
`

export const HeaderWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.l}px;
  font-family: ${({ theme }) => theme.fontFamilies.stratum2};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  color: ${({ theme }) => theme.colors.text.contrast};

  text-transform: uppercase;
  text-align: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing.m}px;

  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${({ theme }) => theme.fontSizes.s}px;
  font-family: ${({ theme }) => theme.fontFamilies.openSans};

  text-align: center;
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  column-gap: ${({ theme }) => theme.spacing.m}px;
  row-gap: ${({ theme }) => theme.spacing.s}px;
`

export const ModalForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  text-align: left;

  gap: ${({ theme }) => theme.spacing.m}px;

  > * {
    grid-column: span 2;
  }
`
