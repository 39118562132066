import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { getModalInfo } from './lineOfficer.utils'
import {
  Header,
  Main,
  StyledLogo,
  HeaderText,
  StyledForm,
  RadioWrapper,
  RadioError,
  StyledInfoIcon,
  IconLabelWrapper
} from './LineOfficer.styles'
import { SVG_LOGO_HEADER_SIZE, requiredValidation, gpaValidation, jobCategoryOptions, serviceTypeOptions, highestDegreeOptions, majorOptions, HELP_ICON_SIZE, RADIO_VALUES } from './lineOfficer.constants'
import { FormTextField, FormRadio, FormCheckbox, Button, FormSelect, FormDatepicker } from '@aimhigh/web-components/web'
import { theme as ahaTheme } from '@aimhigh/theme'
import { useForm } from 'react-hook-form'
import LineOfficerModal from './LineOfficerModal'

function LineOfficerGettingStarted() {
  const [modalInfo, setModalInfo] = useState({
    type: null,
  })

  const handleFormSubmit = values => {
    const modalResult = getModalInfo(values)
    if (modalResult) {
      setModalInfo(modalResult)
    }
  }

  const handleModalClose = () => {
    setModalInfo({
      type: null,
    })
  }

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      interest: false,
      accuracyConfirmation: false,
    },
  })

  const errors = formHooks.formState.errors

  const selectProps = {
    rules: requiredValidation,
    placeholder: 'Select',
    dark: true,
  }

  const datepickerProps = {
    rules: requiredValidation,
    style: { gridColumn: 'span 1' },
    dateFormat: 'MM/dd/yyyy',
    placeholder: 'MM/DD/YYYY',
    dark: true,
  }

  const [showRelativeInfo, setShowRelativeInfo] = useState(false)
  const [showOtherDegreeField, setShowOtherDegreeField] = useState(false)

  const { watch } = formHooks
  const relativeValue = watch('relative')
  const majorValue = watch('major')
  const interestValue = watch('interest')
  const accuracyValue = watch('accuracyConfirmation')
  const militaryServiceValue = watch('militaryService')

  const isSubmitDisabled = !interestValue || !accuracyValue

  useEffect(() => {
    setShowRelativeInfo(relativeValue === RADIO_VALUES.YES)
  }, [relativeValue])

  useEffect(() => {
    setShowOtherDegreeField(majorValue?.value === 'other')
  }, [majorValue])

  return (
    <ThemeProvider theme={ ahaTheme }>
      <LineOfficerModal
        isOpen={ !!modalInfo.type }
        onClose={ handleModalClose }
        type={ modalInfo.type }
      />
      <Header>
        <StyledLogo size={ SVG_LOGO_HEADER_SIZE } color={ ahaTheme.colors.text.white } />
        <HeaderText>
          <h1>Getting Started</h1>
          <p>These questions will help us get you started on<br />the right track. Please provide your answers to<br />begin the process.</p>
        </HeaderText>
      </Header>
      <Main>
        <section>
          <StyledForm
            onSubmit={ handleFormSubmit }
            formHooks={ formHooks }>
            <FormSelect label={ `Select the Job Category you're interested in` } name='jobCategory' options={ jobCategoryOptions } { ...selectProps } />
            <FormSelect label={ `Select the Service Type you're interested in` } name='serviceType' options={ serviceTypeOptions } { ...selectProps } />
            <FormSelect label='What is the Highest Degree you have earned?' name='degree' options={ highestDegreeOptions } { ...selectProps } />
            <FormSelect label='What was your Degree Major?' name='major' options={ majorOptions } { ...selectProps } />
            { showOtherDegreeField && (
              <FormTextField
                label='Please Specify your Degree Major'
                name='otherDescription'
                dark
              />
            ) }
            <FormDatepicker label='Graduation Date' name='graduationDate' { ...datepickerProps } />
            <FormTextField
              label={
                <IconLabelWrapper>
                  GPA <StyledInfoIcon color={ ahaTheme.colors.system.actionLight } size={ HELP_ICON_SIZE } />
                </IconLabelWrapper>
              }
              type='number'
              name='gpa'
              style={ { gridColumn: 'span 1' } }
              rules={ gpaValidation }
              placeholder='0.0 - 4.0'
              dark
            />
            <FormDatepicker label='Date of Birth' name='dob' { ...datepickerProps } />
            <RadioWrapper $isError={ errors?.enlisted }>
              <p>Are you currently Enlisted as an Active Duty Air Force member?</p>
              <FormRadio label='Yes' name='enlisted' value={ RADIO_VALUES.YES } rules={ requiredValidation } dark />
              <FormRadio label='No' name='enlisted' value={ RADIO_VALUES.NO } rules={ requiredValidation } dark />
              { errors?.enlisted && <RadioError>{ errors.enlisted.message }</RadioError> }
            </RadioWrapper>
            <RadioWrapper $isError={ errors?.militaryService }>
              <p>Have you served in the Military before?</p>
              <FormRadio label='Yes' name='militaryService' value={ RADIO_VALUES.YES } rules={ requiredValidation } dark />
              <FormRadio label='No' name='militaryService' value={ RADIO_VALUES.NO } rules={ requiredValidation } dark />
              { errors?.militaryService && <RadioError>{ errors.militaryService.message }</RadioError> }
            </RadioWrapper>
            { militaryServiceValue === RADIO_VALUES.YES && (
              <RadioWrapper $isError={ errors?.commissioned }>
                <p>Were you commissioned as an Officer?</p>
                <FormRadio label='Yes' name='commissioned' value={ RADIO_VALUES.YES } rules={ requiredValidation } dark />
                <FormRadio label='No' name='commissioned' value={ RADIO_VALUES.NO } rules={ requiredValidation } dark />
                { errors?.commissioned && <RadioError>{ errors.commissioned.message }</RadioError> }
              </RadioWrapper>
            ) }
            <RadioWrapper $isError={ errors?.relative }>
              <p>
                Do you have a relative in Congress or a military rank higher than a{ ' ' }
                <IconLabelWrapper>
                  Colonel?
                  <StyledInfoIcon
                    color={ ahaTheme.colors.system.actionLight }
                    size={ HELP_ICON_SIZE }
                  />
                </IconLabelWrapper>
              </p>
              <FormRadio label='Yes' name='relative' value={ RADIO_VALUES.YES } rules={ requiredValidation } dark />
              <FormRadio label='No' name='relative' value={ RADIO_VALUES.NO } rules={ requiredValidation } dark />
              { errors?.relative && <RadioError>{ errors.relative.message }</RadioError> }
            </RadioWrapper>

            { showRelativeInfo && (
              <FormTextField
                label={ `Please provide the relative's name and rank` }
                name='relativeName'
                dark
              />
            ) }

            <FormCheckbox label='I am interested in becoming an Active Duty Officer in the United States Air Force' name='interest' dark />
            <FormCheckbox label='I confirm that all the information provided is accurate and complete' name='accuracyConfirmation' dark />
            <Button type='submit' dark disabled={ isSubmitDisabled }>CONTINUE TO REGISTRATION</Button>
          </StyledForm>
        </section>
      </Main>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerGettingStarted)
