import { getAge } from '../../api/date.util'
import { RADIO_VALUES } from './lineOfficer.constants'

export const getModalInfo = values => {
  if (values.enlisted === RADIO_VALUES.YES) {
    return { type: 'enlisted' }
  }

  const age = getAge(values.dob)
  if (age < 17 || (age > 42 && values.militaryService !== RADIO_VALUES.YES)) {
    return { type: 'age' }
  }

  const isEducationIneligible =
    values.gpa < 2 ||
    values.degree?.value === 'current_high_school_student' ||
    values.degree?.value === 'high_school_graduate_or_ged' ||
    values.degree?.value === 'associates' ||
    values.commissioned === RADIO_VALUES.YES

  if (isEducationIneligible) {
    return { type: 'education' }
  }

  const needsRecruiter =
    values.relative === RADIO_VALUES.YES ||
    // [/* high demand tech degrees */].includes(values.major?.value) ||
    // [/* high priority categories */].includes(values.jobCategory?.value) ||
    values.serviceType?.value === 'part_time'

  if (needsRecruiter) {
    return { type: 'recruiter' }
  }

  return null
}
