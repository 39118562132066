const CONFIG = {
  apiUrl: '',
  afreCmsServiceUrl: '',
}

switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    Object.assign(CONFIG, {
      apiUrl: process.env.REACT_APP_API_URL || 'https://aha-api.cce.af.mil',
      afreCmsServiceApiUrl: process.env.REACT_APP_AFRE_CMS_API_URL || 'https://aha-cms2.cce.af.mil/api',
      websiteHostUrl: process.env.REACT_APP_HOST || 'https://aha.cce.af.mil',
      imageResizeUrl: process.env.REACT_APP_RESIZE_URL || 'https://aha-cms.cce.af.mil/s3',
    })
    break
  case 'staging':
    Object.assign(CONFIG, {
      apiUrl: process.env.REACT_APP_API_URL || 'https://aha-api.test.cce.af.mil',
      afreCmsServiceApiUrl: process.env.REACT_APP_AFRE_CMS_API_URL || 'https://aha-cms2.test.cce.af.mil/api',
      websiteHostUrl: process.env.REACT_APP_HOST || 'https://aha.test.cce.af.mil',
      imageResizeUrl: process.env.REACT_APP_RESIZE_URL || 'https://aha-cms.test.cce.af.mil/s3',
    })
    break
  default:
    Object.assign(CONFIG, {
      apiUrl: process.env.REACT_APP_API_URL || 'https://dev-api.aviture-afrs.com',
      afreCmsServiceApiUrl: process.env.REACT_APP_AFRE_CMS_API_URL || 'https://devcms2.aviture-afrs.com/api',
      websiteHostUrl: process.env.REACT_APP_HOST || 'https://referral.dev-api.aviture-afrs.com',
      imageResizeUrl: process.env.REACT_APP_RESIZE_URL || 'https://devcms.aviture-afrs.com/s3',
    })
    break
}

export const ROLES = {
  GENERAL: 'general',
  MANAGER: 'manager',
  RECRUITER: 'recruiter',
  ADMIN: 'admin',
  MASTER_ADMIN: 'masteradmin',
  MASS_ALERTS: 'role_massalerts',
  UNIT_MAINTENANCE: 'role_unitmaintenance',
  DEP: 'dep',
  FLIGHT_CHIEF: 'flightchief',
  PROD_SUPER: 'productionsuper',
  COMMANDER: 'commander',
  STUDENT_RECRUITER: 'role_studentrecruiter',
}

export const OPA_ROLES = {
  MTL: 'role_opa_general',
  ADMIN: 'role_opa_admin',
  MTI: 'role_opa_mti',
}

export const ROLE_DISPLAY_NAMES = {
  masteradmin: 'Master Admin',
  admin: 'Admin',
  commander: 'Commander',
  productionsuper: 'Production Super',
  flightchief: 'Flight Chief',
  recruiter: 'Recruiter',
  manager: 'Senior Leader',
  // eslint-disable-next-line camelcase
  role_massalerts: 'Mass Alerts',
  // eslint-disable-next-line camelcase
  role_unitmaintenance: 'Unit Maintenance',
  dep: 'DEP',
  general: 'Public',
  // eslint-disable-next-line camelcase
  role_studentrecruiter: 'Student Recruiter',
  // eslint-disable-next-line camelcase
  role_opa_general: 'OPA MTL',
  // eslint-disable-next-line camelcase
  role_opa_admin: 'OPA Admin',
  // eslint-disable-next-line camelcase
  role_opa_mti: 'OPA MTI',
}

export default {
  ...CONFIG,
}
