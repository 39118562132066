import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const SectionTitleContainer = styled.div`
font-size: ${props => props.theme.typography.fontSizes.xs}px;
font-weight: ${props => props.theme.typography.fontWeights.bold};
// color: ${props => (props.hasError ? props.theme.colors.red.default : props.theme.colors.label)};
display: block;
text-transform: uppercase;
// padding: ${props => props.theme.spacing.s}px;
// padding-bottom: 0;
`

const FormSectionTitle = ({ title, ...props }) => {
  return (
    <SectionTitleContainer { ...props }>
      { title || '' }
    </SectionTitleContainer>
  )
}

FormSectionTitle.propTypes = {
  title: PropTypes.string,
}

export default FormSectionTitle
