import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Dimmer,
  Header,
  Icon
} from 'semantic-ui-react'
import PageTitle from '../components/Page/PageTitle/PageTitle'
import LoginForm from '../forms/auth/login.form'
import { selectLoginEmailLoading } from '../redux/selectors/auth.selectors'
import Colors from '../theme/colors'

const Wrapper = styled.div(props => ({
  backgroundColor: props.theme.colors.page.background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto',
  height: '100%',
}))

const LoginPageContainer = styled.div(props => ({
  backgroundColor: props.theme.colors.page.background,
  color: Colors.white.default,
  minWidth: 375,
}))

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

class LoginPage extends React.PureComponent {
  render() {
    const { isLoading } = this.props
    const { from } = this.props.location.state || { from: { pathname: '/login' } }
    return (
      <Wrapper>
        <Dimmer.Dimmable dimmed={ isLoading }>
          <LoginPageContainer>
            <FormContainer>
              <PageTitle />
              <LoginForm routedFrom={ from } />
            </FormContainer>
          </LoginPageContainer>

          <Dimmer page active={ isLoading }>
            <Header as='h2' icon inverted>
              <Icon loading size='big' name='spinner' />
            </Header>
          </Dimmer>
        </Dimmer.Dimmable>
      </Wrapper>
    )
  }
}

LoginPage.propTypes = {
  location: PropTypes.object,
  isLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    isLoading: selectLoginEmailLoading(state),
  }
}

const mapActionsToProps = () => {
  return {
  }
}

const ConnectedLoginPage = connect(mapStateToProps, mapActionsToProps)(LoginPage)

export default withRouter(ConnectedLoginPage)
