import React from 'react'
import OpaAdminRoute from '../opa/navigationRoutes/OpaAdminRoute'
import { OpaMtlRoute } from '../opa/navigationRoutes/OpaMtlRoute'
import { AfscManagementPage } from '../opa/pages/AfscManagementPage'
import { GainBaseManagementPage } from '../opa/pages/GainBaseManagementPage'
import { GainUnitManagementPage } from '../opa/pages/GainUnitManagementPage'
import { MtiEmailManagement } from '../opa/pages/MtiEmailManagement'
import { MtlFindOrdersPage } from '../opa/pages/MtlFindOrdersPage'
import { OpaUsersPage } from '../opa/pages/OpaUsersPage'
import { ProcessWeekGroupPage } from '../opa/pages/ProcessWeekGroupPage'
import { SectionManagementPage } from '../opa/pages/SectionManagementPage'
import { UploadWeekGroupPage } from '../opa/pages/UploadWeekGroupPage'
import { VariableManagementPage } from '../opa/pages/VariableManagementPage'
import LoginConsentPage from '../pages/LoginConsentPage'
import EventListPage from '../components/event/EventListPage'
import Id2aTrainingVideoForm from '../pages/lead/Id2aTrainingVideo'
import PrescreenLeadForm from '../pages/lead/PrescreenLeadForm'
import ListRoleRequestsPage from '../pages/listRoleRequests/ListRoleRequestsPage'
import LoginPage from '../pages/login.page'
import PrivacyPolicyPage from '../pages/privacyPolicy.page'
import ProfilePage from '../pages/profile/ProfilePage'
import ReferralPage from '../pages/referral.page'
import StarrPage from '../pages/starr/starr.page'
import starrCitationFirstReferral from '../pages/starr/starr.citation.first.referral'
import starrCitationThirdReferral from '../pages/starr/starr.citation.third.referral'
import starrCitationFifthReferral from '../pages/starr/starr.citation.fifth.referral'
import TakeawayPage from '../pages/starr/take.away.page'
import ReferralListPage from '../pages/referralList.page'
import RecruiterCountsPage from '../pages/reports/RecruiterCountsPage'
import AfcepRequest from '../pages/afcep/afcepLandingPage/AfcepLandingPage'
import RoleRequestPage from '../pages/roleRequest/role.request.page'
import FlightPhotoSharePage from '../pages/share/FlightPhotoSharePage'
import AppSharePage from '../components/appShare/AppSharePage'
import CareerSharePage from '../components/careers/CareerSharePage'
import EventSharePage from '../components/event/EventSharePage'
import PhotoSharePage from '../pages/share/PhotoSharePage'
import SquadronFlightsPage from '../pages/squadron/flights.page'
import SquadronPage from '../pages/squadron/squadrons.page'
import Unsubscribe from '../pages/unsubscribe/Unsubscribe'
import UnsubscribeConfirmation from '../pages/unsubscribe/UnsubscribeConfirmation'
import UsersPage from '../pages/users/UsersPage'
import UserActivityPage from '../pages/users/activity/UserActivityPage'
import AdminRoute from './adminRoute'
import AuthRoute from './authRoute'
import AfcepRequestForm from '../pages/afcep/AfcepRequestForm'
import { AfcepSuccess } from '../pages/afcep/AfcepSuccessPage'
import AfcepInvalidEventPage from '../pages/afcep/AfcepInvalidEventPage'
import MassAlertsForm from '../pages/massAlerts/MassAlertsForm'
import MassAlertsRoute from './MassAlertRoute'
import { ROLES } from '../config'
import { DeleteAccountPage } from '../pages/deleteAccount/DeleteAccountPage'
import { DeleteAccountSuccessPage } from '../pages/deleteAccount/DeleteAccountSuccessPage'
import RecruiterLeadPage from '../pages/profile/RecruiterLeadPage'
import LineOfficerGettingStarted from '../pages/lineOfficer/LineOfficerGettingStarted'
import LineOfficerRegistration from '../pages/lineOfficer/LineOfficerRegistration'
import LineOfficerEmailVerification from '../pages/lineOfficer/LineOfficerEmailVerification'
import LineOfficerDashboard from '../pages/lineOfficer/LineOfficerDashboard'
import LineOfficerInitialApplication from '../pages/lineOfficer/LineOfficerInitialApplication'
import LineOfficerTimeline from '../pages/lineOfficer/LineOfficerTimeline'
import LineOfficerApplicationSteps from '../pages/lineOfficer/LineOfficerApplicationSteps'

const CMS_LOGIN_PAGE = '/'
const EVENT_LIST_PAGE = '/events'
const RECRUITER_COUNTS_REPORT_PAGE = '/admin/recruiterCounts'
const REFERRAL_PAGE = '/referral'
const REFERRAL_LIST_PAGE = '/referrals'
const USERS_PAGE = '/admin/users'
const USER_ACTIVITY_ROUTE = '/users/activity'
const ROLE_REQUESTS_PAGE = '/admin/listRoleRequests'
const PRIVACY_POLICY_PAGE = '/privacyPolicy'
const LOGIN_PAGE = '/login'
export const SHARE_BMT_PHOTO_PATH = '/share/bmtphoto'
const SHARE_BMT_PHOTO = `${SHARE_BMT_PHOTO_PATH}/:photoId`
const SHARE_FLIGHT_PHOTO = '/share/flightphoto/:flight/:activity/:page/:index'
export const SHARE_CAREER_PATH = '/share/career'
const SHARE_CAREER = `${SHARE_CAREER_PATH}/:slug`
export const SHARE_EVENT_PATH = '/share/event'
const SHARE_EVENT = `${SHARE_EVENT_PATH}/:eventId`
const SQUADRONS_PAGE = '/admin/squadrons'
const FLIGHTS_PAGE = '/squadrons/flights'
export const SHARE_APP = '/share/app'
const NOTIFICATIONS_PAGE = `/admin/notifications`
const PROFILE_PAGE = `/recruiter/:userId`
const RECRUITER_LEAD_PAGE = '/lead/recruiter/:qrSort'
const ROLE_REQUEST_PAGE = '/roleRequest'
const UPLOAD_WEEK_GROUP_PAGE = '/admin/uploadWeekGroup'
const OPA_USERS_PAGE = '/admin/opaUsers'
const VARIABLES_PAGE = '/admin/editTemplateInfo'
const SECTIONS_PAGE = '/admin/sectionEditor'
const AFSC_MANAGEMENT_PAGE = '/admin/afscManagement'
const BASE_MANAGEMENT_PAGE = '/admin/gainBaseManagement'
const UNIT_MANAGEMENT_PAGE = '/admin/gainUnitManagement'
const PROCESS_WEEK_GROUP_PAGE = '/admin/processWeekGroup'
const UNSUBSCRIBE = '/unsubscribe/:emailPreferencesId'
const UNSUBSCRIBE_CONFIRMATION = '/unsubscribeConfirmation'
const FIND_ORDERS_PAGE = '/admin/findOrders'
const EMAIL_MANAGEMENT_PAGE = '/admin/emailManagement'
const PRESCREEN_LEAD_FORM = '/prescreen/:leadId'
const ID2A_TRAINING_VIDEO_FORM = '/id2aTrainingVideo/:leadId'
const AFCEP_REQUEST = '/admin/afcepRequest'
const AFCEP_REQUEST_FORM = '/admin/afcepRequest/form'
const AFCEP_INVALID_EVENT = '/admin/afcepRequest/invalidEvent'
const AFCEP_SUCCESS_PAGE = '/admin/afcepRequest/form/success'
const STARR_PAGE = '/starr'
const STARR_CITATION1 = '/starrCitation1'
const STARR_CITATION3 = '/starrCitation3'
const STARR_CITATION5 = '/starrCitation5'
const TAKEAWAY_PAGE = '/takeaway'
const DELETE_ACCOUNT_PAGE = '/deleteAccount'
const DELETE_ACCOUNT_PAGE_SUCCESS = '/deleteAccount/success'

const LINE_OFFICER_GETTING_STARTED = '/lineOfficer/gettingStarted'
const LINE_OFFICER_REGISTRATION = '/lineOfficer/registration'
const LINE_OFFICER_EMAIL_VERIFICATION = '/lineOfficer/emailVerification'
const LINE_OFFICER_DASHBOARD = '/lineOfficer/dashboard'
const LINE_OFFICER_INITIAL_APPLICATION = '/lineOfficer/initialApplication'
const LINE_OFFICER_TIMELINE = '/lineOfficer/timeline'
const LINE_OFFICER_APPLICATION_STEPS = '/lineOfficer/applicationSteps'

export const NAV_ROUTES = {
  CMS_LOGIN_PAGE,
  EVENT_LIST_PAGE,
  RECRUITER_COUNTS_REPORT_PAGE,
  REFERRAL_PAGE,
  REFERRAL_LIST_PAGE,
  USERS_PAGE,
  USER_ACTIVITY_ROUTE,
  ROLE_REQUESTS_PAGE,
  PRIVACY_POLICY_PAGE,
  LOGIN_PAGE,
  SHARE_BMT_PHOTO,
  SHARE_FLIGHT_PHOTO,
  SHARE_CAREER,
  SHARE_EVENT,
  SQUADRONS_PAGE,
  FLIGHTS_PAGE,
  SHARE_APP,
  NOTIFICATIONS_PAGE,
  PROFILE_PAGE,
  RECRUITER_LEAD_PAGE,
  ROLE_REQUEST_PAGE,
  UPLOAD_WEEK_GROUP_PAGE,
  OPA_USERS_PAGE,
  VARIABLES_PAGE,
  SECTIONS_PAGE,
  AFSC_MANAGEMENT_PAGE,
  BASE_MANAGEMENT_PAGE,
  UNIT_MANAGEMENT_PAGE,
  PROCESS_WEEK_GROUP_PAGE,
  UNSUBSCRIBE,
  UNSUBSCRIBE_CONFIRMATION,
  FIND_ORDERS_PAGE,
  EMAIL_MANAGEMENT_PAGE,
  PRESCREEN_LEAD_FORM,
  ID2A_TRAINING_VIDEO_FORM,
  AFCEP_REQUEST,
  AFCEP_REQUEST_FORM,
  AFCEP_SUCCESS_PAGE,
  STARR_PAGE,
  STARR_CITATION1,
  STARR_CITATION3,
  STARR_CITATION5,
  TAKEAWAY_PAGE,
  AFCEP_INVALID_EVENT,
  DELETE_ACCOUNT_PAGE,
  DELETE_ACCOUNT_PAGE_SUCCESS,
  LINE_OFFICER_GETTING_STARTED,
  LINE_OFFICER_REGISTRATION,
  LINE_OFFICER_EMAIL_VERIFICATION,
  LINE_OFFICER_DASHBOARD,
  LINE_OFFICER_INITIAL_APPLICATION,
  LINE_OFFICER_TIMELINE,
  LINE_OFFICER_APPLICATION_STEPS,
}

export const getAhaSidebarRoutes = (roles = []) => {
  let pages = {}
  if (roles.includes(ROLES.MASTER_ADMIN)) {
    pages = { RECRUITER_COUNTS_REPORT_PAGE }
  }
  if (roles.includes(ROLES.ADMIN) || roles.includes(ROLES.MASTER_ADMIN)) {
    pages = {
      ...pages,
      USERS_PAGE,
      ROLE_REQUESTS_PAGE,
      SQUADRONS_PAGE,
    }
  }
  if (roles.includes(ROLES.MASS_ALERTS)) {
    pages = {
      ...pages,
      NOTIFICATIONS_PAGE,
    }
  }
  return pages
}

export const getOpaSidebarRoutes = (shouldShowSectionEditingPage, shouldShowAfscManagementPage) => {
  const opaRoutes = {}
  if (shouldShowAfscManagementPage) {
    opaRoutes.AFSC_MANAGEMENT_PAGE = AFSC_MANAGEMENT_PAGE
  }

  opaRoutes.BASE_MANAGEMENT_PAGE = BASE_MANAGEMENT_PAGE
  opaRoutes.UNIT_MANAGEMENT_PAGE = UNIT_MANAGEMENT_PAGE

  opaRoutes.VARIABLES_PAGE = VARIABLES_PAGE
  opaRoutes.EMAIL_MANAGEMENT_PAGE = EMAIL_MANAGEMENT_PAGE
  if (shouldShowSectionEditingPage) {
    opaRoutes.SECTIONS_PAGE = SECTIONS_PAGE
  }
  opaRoutes.UPLOAD_WEEK_GROUP_PAGE = UPLOAD_WEEK_GROUP_PAGE
  opaRoutes.PROCESS_WEEK_GROUP_PAGE = PROCESS_WEEK_GROUP_PAGE
  opaRoutes.OPA_USERS_PAGE = OPA_USERS_PAGE
  opaRoutes.FIND_ORDERS_PAGE = FIND_ORDERS_PAGE
  return opaRoutes
}

const routes = (shouldShowSectionEditingPage, shouldShowAfscManagementPage, shouldShowLineOfficerPages) => {
  const pages = [
    {
      path: NAV_ROUTES.CMS_LOGIN_PAGE,
      children: LoginConsentPage,
    },
    {
      path: NAV_ROUTES.EVENT_LIST_PAGE,
      children: EventListPage,
    },
    {
      path: NAV_ROUTES.RECRUITER_COUNTS_REPORT_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ RecruiterCountsPage } />),
    },
    {
      path: NAV_ROUTES.REFERRAL_PAGE,
      children: ReferralPage,
    },
    {
      path: NAV_ROUTES.PRIVACY_POLICY_PAGE,
      children: PrivacyPolicyPage,
    },
    {
      path: NAV_ROUTES.LOGIN_PAGE,
      children: LoginPage,
    },
    {
      path: NAV_ROUTES.SHARE_BMT_PHOTO,
      children: PhotoSharePage,
    },
    {
      path: NAV_ROUTES.SHARE_FLIGHT_PHOTO,
      children: FlightPhotoSharePage,
    },
    {
      path: NAV_ROUTES.SHARE_CAREER,
      children: CareerSharePage,
    },
    {
      path: NAV_ROUTES.SHARE_EVENT,
      children: EventSharePage,
    },
    {
      path: NAV_ROUTES.REFERRAL_LIST_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ ReferralListPage } />),
    },
    {
      path: NAV_ROUTES.SHARE_APP,
      children: AppSharePage,
    },
    {
      path: NAV_ROUTES.USERS_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ UsersPage } />),
    },
    {
      path: NAV_ROUTES.USER_ACTIVITY_ROUTE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ UserActivityPage } />),
    },
    {
      path: NAV_ROUTES.ROLE_REQUESTS_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ ListRoleRequestsPage } />),
    },
    {
      path: NAV_ROUTES.SQUADRONS_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ SquadronPage } />),
    },
    {
      path: NAV_ROUTES.FLIGHTS_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AdminRoute component={ SquadronFlightsPage } />),
    },
    {
      path: NAV_ROUTES.PROFILE_PAGE,
      children: ProfilePage,
    },
    {
      path: NAV_ROUTES.RECRUITER_LEAD_PAGE,
      children: RecruiterLeadPage,
    },
    {
      path: NAV_ROUTES.UNSUBSCRIBE,
      children: Unsubscribe,
    },
    {
      path: NAV_ROUTES.UNSUBSCRIBE_CONFIRMATION,
      children: UnsubscribeConfirmation,
    },
    {
      path: NAV_ROUTES.ROLE_REQUEST_PAGE,
      // eslint-disable-next-line react/display-name
      children: () => (<AuthRoute component={ RoleRequestPage } />),
    },
    {
      path: NAV_ROUTES.UPLOAD_WEEK_GROUP_PAGE,
      children: () => (<OpaAdminRoute component={ UploadWeekGroupPage } />),
    },
    {
      path: NAV_ROUTES.PROCESS_WEEK_GROUP_PAGE,
      children: () => (<OpaAdminRoute component={ ProcessWeekGroupPage } />),
    },
    {
      path: NAV_ROUTES.OPA_USERS_PAGE,
      children: () => (<OpaAdminRoute component={ OpaUsersPage } />),
    },
    {
      path: NAV_ROUTES.VARIABLES_PAGE,
      children: () => (<OpaAdminRoute component={ VariableManagementPage } />),
    },
    {
      path: NAV_ROUTES.BASE_MANAGEMENT_PAGE,
      children: () => (<OpaAdminRoute component={ GainBaseManagementPage } />),
    },
    {
      path: NAV_ROUTES.UNIT_MANAGEMENT_PAGE,
      children: () => (<OpaAdminRoute component={ GainUnitManagementPage } />),
    },
    {
      path: NAV_ROUTES.FIND_ORDERS_PAGE,
      children: () => (<OpaMtlRoute component={ MtlFindOrdersPage } />),
    },
    {
      path: NAV_ROUTES.EMAIL_MANAGEMENT_PAGE,
      children: () => (<OpaAdminRoute component={ MtiEmailManagement } />),
    },
    {
      path: NAV_ROUTES.PRESCREEN_LEAD_FORM,
      children: PrescreenLeadForm,
    },
    {
      path: NAV_ROUTES.ID2A_TRAINING_VIDEO_FORM,
      children: Id2aTrainingVideoForm,
    },
    {
      path: NAV_ROUTES.AFCEP_REQUEST,
      children: AfcepRequest,
    },
    {
      path: NAV_ROUTES.AFCEP_INVALID_EVENT,
      children: AfcepInvalidEventPage,
    },
    {
      path: NAV_ROUTES.STARR_PAGE,
      children: StarrPage,
    },
    {
      path: NAV_ROUTES.STARR_CITATION1,
      children: starrCitationFirstReferral,
    },
    {
      path: NAV_ROUTES.STARR_CITATION3,
      children: starrCitationThirdReferral,
    },
    {
      path: NAV_ROUTES.STARR_CITATION5,
      children: starrCitationFifthReferral,
    },
    {
      path: NAV_ROUTES.TAKEAWAY_PAGE,
      children: TakeawayPage,
    },
    {
      path: NAV_ROUTES.AFCEP_REQUEST_FORM,
      children: AfcepRequestForm,
    },
    {
      path: NAV_ROUTES.AFCEP_SUCCESS_PAGE,
      children: AfcepSuccess,
    },
    {
      path: NAV_ROUTES.DELETE_ACCOUNT_PAGE,
      children: () => (<AuthRoute component={ DeleteAccountPage } />),
    },
    {
      path: NAV_ROUTES.DELETE_ACCOUNT_PAGE_SUCCESS,
      children: DeleteAccountSuccessPage,
    },
    {
      path: NAV_ROUTES.NOTIFICATIONS_PAGE,
      children: () => (<MassAlertsRoute component={ MassAlertsForm } />),
      allowedRoles: [ROLES.MASS_ALERTS],
    },
  ]
  if (shouldShowSectionEditingPage) {
    pages.push({
      path: NAV_ROUTES.SECTIONS_PAGE,
      children: () => (<OpaAdminRoute component={ SectionManagementPage } />),
    })
  }
  if (shouldShowAfscManagementPage) {
    pages.push({
      path: NAV_ROUTES.AFSC_MANAGEMENT_PAGE,
      children: () => (<OpaAdminRoute component={ AfscManagementPage } />),
    })
  }

  if (shouldShowLineOfficerPages) {
    pages.push({
      path: NAV_ROUTES.LINE_OFFICER_GETTING_STARTED,
      children: LineOfficerGettingStarted,
    },
    {
      path: NAV_ROUTES.LINE_OFFICER_REGISTRATION,
      children: LineOfficerRegistration,
    },
    {
      path: NAV_ROUTES.LINE_OFFICER_EMAIL_VERIFICATION,
      children: LineOfficerEmailVerification,
    },
    {
      path: NAV_ROUTES.LINE_OFFICER_DASHBOARD,
      children: LineOfficerDashboard,
    },
    {
      path: NAV_ROUTES.LINE_OFFICER_INITIAL_APPLICATION,
      children: LineOfficerInitialApplication,
    },
    {
      path: NAV_ROUTES.LINE_OFFICER_TIMELINE,
      children: LineOfficerTimeline,
    },
    {
      path: NAV_ROUTES.LINE_OFFICER_APPLICATION_STEPS,
      children: LineOfficerApplicationSteps,
    })
  }

  return pages
}

export default routes
