import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'
import styled from '@emotion/styled'
import { fontSizes } from '../../theme/typography'
import spacing from '../../theme/spacing'

Contact.propTypes = {
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  palette: PropTypes.object.isRequired,
  facebook: PropTypes.string,
  youtube: PropTypes.string,
  linkedIn: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  googleBusinessPageUrl: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.object),
  calendarLink: PropTypes.string,
}

const isIosDevice = userAgent => {
  return (userAgent.indexOf('iPhone') !== -1) || (userAgent.indexOf('iPad') !== -1) || (userAgent.indexOf('iPod') !== -1)
}
// eslint-disable-next-line complexity
function Contact({
  phoneNumber,
  email,
  palette,
  facebook,
  instagram,
  googleBusinessPageUrl,
  linkedIn,
  twitter,
  youtube,
  address,
  city,
  state,
  zipCode,
  documents,
  calendarLink,
}) {
  const ContactItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${spacing.m}px !important;
  `

  const Link = styled.a`
    padding-left: ${spacing.m}px;
    color: ${props => props.palette.text.default};
  `
  const ContactGroup = styled.div`
    font-family: "Open Sans", sans-serif;
    font-size: ${fontSizes.m}px !important;
    color: ${props => props.palette.text.default} !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `
  const ContactColumn = styled.div`
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: ${spacing.l}px;
  `

  const SocialMediaIcons = styled.span`
  padding-right: ${spacing.s}px;
`

  const contactButton = (url, iconName) => {
    return (
      <SocialMediaIcons>
        <Button
          data-testid={ `${iconName}_contactButton` }
          as='a' href={ url } target='_blank' rel='noopener noreferrer'
          className='circular mini ui icon button' >
          <Icon className={ iconName } size='large' />
        </Button>
      </SocialMediaIcons>
    )
  }

  const formatPhoneNumber = phoneNumberString => {
    const cleaned = (`${phoneNumberString}`).replace(/\D/gu, '')
    const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/u)
    if (match) {
      return [match[1], '-', match[2], '-', match[3]].join('')
    }
    return null
  }

  const formattedPhoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : ''
  let recruitingOfficeUrl = `//maps.google.com/maps?q=${address},${city},${state} ${zipCode}`
  if (isIosDevice(navigator.userAgent)) {
    recruitingOfficeUrl = `//maps.apple.com/maps?q=${address},${city},${state} ${zipCode}`
  }
  return (
    <ContactGroup data-testid='Contact' palette={ palette }>
      <ContactColumn>
        { !!formattedPhoneNumber &&
          <ContactItem data-testid='ContactNumber'>
            <Icon fitted name='phone' />
            <Link palette={ palette } href={ `tel:${formattedPhoneNumber}` }>{ formattedPhoneNumber }</Link>
          </ContactItem> }
        { !!email &&
          <ContactItem data-testid='ContactEmail'>
            <Icon fitted name='mail' data-testid='email-icon' />
            <Link palette={ palette } rel='noopener noreferrer' target='_blank' href={ `mailto:${email}` }>{ email }</Link>
          </ContactItem>
        }
        { !!documents?.length ? documents.map((document, index) => (
          <ContactItem key={ index }>
            <Icon fitted name='file pdf outline' />
            <Link palette={ palette } rel='noopener noreferrer' target='_blank' href={ document.documentLink }>{ document.documentTitle }</Link>
          </ContactItem>
        )) : <></> }
        { !!calendarLink &&
          <ContactItem>
            <Icon fitted name='calendar alternate outline' />
            <Link palette={ palette } rel='noopener noreferrer' target='_blank' href={ calendarLink }>Schedule an Appointment</Link>
          </ContactItem>
        }
      </ContactColumn>
      <ContactColumn>
        { !!address &&
          <ContactItem>
            <Icon fitted name='map marker alternate' />
            <Link palette={ palette } rel='noopener noreferrer' target='_blank' href={ recruitingOfficeUrl } style={ { marginLeft: 2 } }>
              <div>Recruiting Office</div>
              <div>{ address }</div>
              <div>{ `${city || ''}, ${state || ''} ${zipCode || ''}` }</div>
            </Link>
          </ContactItem>
        }
      </ContactColumn>
      <ContactColumn style={ { flexBasis: '100%' } }>
        { !!facebook && contactButton(facebook, 'facebook f') }
        { !!youtube && contactButton(youtube, 'youtube') }
        { !!linkedIn && contactButton(linkedIn, 'linkedin') }
        { !!twitter && contactButton(twitter, 'twitter') }
        { !!instagram && contactButton(instagram, 'instagram') }
        { !!googleBusinessPageUrl && contactButton(googleBusinessPageUrl, 'google') }
      </ContactColumn>
    </ContactGroup>
  )
}

export default Contact
