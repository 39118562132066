import styled from '@emotion/styled'
import AfBirdIcon from '../../icons/AfBird'
import InfoIcon from '../../icons/Info'
import { Form, Button } from '@aimhigh/web-components/web'
import { HEADER_HEIGHT, FOOTER_HEIGHT } from './lineOfficer.constants'

export const oneColStyle = { gridColumn: 'span 1' }
export const twoColStyle = { gridColumn: 'span 2' }
export const threeColStyle = { gridColumn: 'span 3' }
export const fourColStyle = { gridColumn: 'span 4' }

export const Header = styled.header`
  position: relative;

  padding: ${({ theme }) => theme.spacing.l}px 5% 0;
  color: ${({ theme }) => theme.colors.text.white};
  background: ${({ theme }) => theme.colors.brand.brandDark};
  text-align: center;

  height: ${({ $customHeight }) => $customHeight ?? HEADER_HEIGHT}px;
  border-bottom: none;
`

export const FloatingHeaderIcons = styled.nav`
    position: absolute;
    top: ${({ theme }) => theme.spacing.xl}px;
    left: 0;

    display: grid;
    grid-template-columns: auto 1fr auto auto;

    gap: ${({ theme }) => theme.spacing.m}px;
    padding: 0 ${({ theme }) => theme.spacing.l}px;
    width: 100%;

    z-index: 1;

    > svg {
        cursor: pointer;
    }
`

export const HeaderText = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  padding-top: ${({ theme }) => theme.spacing.xxl}px;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.s}px;

  > h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxl}px;
    line-height: ${({ theme }) => theme.fontSizes.xxl}px;
    font-family: ${({ theme }) => theme.fontFamilies.stratum2};
    text-transform: uppercase;
    margin: 0;
  }

  > p {
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
  }
`

export const Main = styled.main`
  padding: ${({ theme }) => `${theme.spacing.xl}px ${theme.spacing.m}px`};
  background: ${({ theme }) => theme.colors.brand.brandDark};
  min-height: ${({ $withFooter, $customHeight }) => `calc(100vh - ${$customHeight ?? HEADER_HEIGHT}px - ${$withFooter ? FOOTER_HEIGHT : 0}px)`};

  > section {
    max-width: 500px;
    margin: auto;
  }
`

export const Footer = styled.footer`
  background: ${({ theme }) => theme.colors.brand.brandDark};
  color: ${({ theme }) => theme.colors.text.white};
  padding: ${({ theme }) => `${theme.spacing.l}px 5%`};

  height: ${FOOTER_HEIGHT}px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.s}px;

  a {
    color: ${({ theme }) => theme.colors.system.actionLight};
  }
`

export const StyledLogo = styled(AfBirdIcon)`
  opacity: 0.25;
`

export const StyledForm = styled(Form)`
  margin: auto;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: ${({ theme }) => theme.spacing.xl}px;

  > * {
    grid-column: span 2;
  }

  label {
    font-size: ${({ theme }) => theme.fontSizes.xm}px;
  }
`

export const RadioWrapper = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-template-columns: auto 1fr;

  row-gap: ${({ theme }) => theme.spacing.m}px;
  column-gap: ${({ theme }) => theme.spacing.xxl}px;

  p {
    grid-column: span 2;

    margin: 0;

    color: ${({ theme }) => theme.colors.text.white};
    font-family: ${({ theme }) => theme.fontFamilies.openSans};
    font-size: ${({ theme }) => theme.fontSizes.xm}px;
  }

  div[role=radio] {
    color: ${({ theme, $isError }) => $isError ? theme.colors.system.negative : theme.colors.text.white};
  }
`

export const RadioError = styled.label`
  color: ${({ theme }) => theme.colors.system.negative};
  font-size: ${({ theme }) => theme.fontSizes.s}px;
  font-family: ${({ theme }) => theme.fontFamilies.openSans};

  grid-column: span 2;
`

export const RegistrationButtons = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing.s}px;
`

export const EmailHighlight = styled.span`
  color: ${({ theme }) => theme.colors.system.actionLight};
  text-decoration: underline;
`

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.s}px;
`

export const VerificationInput = styled.input`
  width: 48px;
  height: 75px;

  font-family: ${({ theme }) => theme.fontFamilies.stratum2};
  font-size: ${({ theme }) => theme.fontSizes.m * 3}px;
  line-height: ${({ theme }) => theme.fontSizes.m * 3}px;
  color: ${({ theme }) => theme.colors.text.white};
  text-align: center;

  border: 1px solid ${({ theme }) => theme.colors.text.nonessential};
  border-radius: ${({ theme }) => theme.borderRadius}px;

  background: transparent;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.background.level1};
  }

  &[type=number]::-webkit-inner-spin-button, 
  &[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
`

export const Description = styled.p`
  margin-top: ${({ theme }) => theme.spacing.s}px;
  margin-bottom: ${({ theme }) => theme.spacing.m * 3}px;

  text-align: center;

  color: ${({ theme }) => theme.colors.text.white};
  font-family: ${({ theme }) => theme.fontFamilies.openSans};
  font-size: ${({ theme }) => theme.fontSizes.s}px;
`

export const IconLabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

export const StyledInfoIcon = styled(InfoIcon)`
  margin-left: ${({ theme }) => theme.spacing.s}px;

  cursor: pointer;
`

export const StyledSection = styled.section`
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  align-items: flex-end;
`

export const DarkDescription = styled.span`
  color: ${({ theme }) => theme.colors.text.nonessential};
  font-family: ${({ theme }) => theme.fontFamilies.openSans};
  font-size: ${({ theme }) => theme.fontSizes.s}px;

  text-align: left;
`

export const TinyButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.xm}px;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 24px;
`
