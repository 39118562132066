import React from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import {
  Header,
  Main,
  StyledLogo,
  HeaderText,
  StyledForm,
  Footer,
  RegistrationButtons,
  StyledSection,
  DarkDescription
} from './LineOfficer.styles'
import { SVG_LOGO_HEADER_SIZE, textFieldValidation, emailValidation, passwordValidation } from './lineOfficer.constants'
import { FormTextField, Button } from '@aimhigh/web-components/web'
import { theme as ahaTheme } from '@aimhigh/theme'
import { useForm } from 'react-hook-form'

function LineOfficerRegistration() {
  const handleFormSubmit = values => {
    // eslint-disable-next-line no-alert
    alert(
      `--form values--\n${Object.keys(values)
        .map(key => {
          const value = values[key]
          return `${key}: ${
            typeof value === 'object' ? JSON.stringify(value) : value
          }`
        })
        .join('\n')}`
    )
  }

  const handleSignIn = () => {
    // eslint-disable-next-line no-alert
    alert('sign in')
  }

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
  })

  const passwordConfirmValidation = {
    ...passwordValidation,
    validate: val => {
      if (val !== formHooks.getValues('password')) {
        return `Passwords do not match`
      }

      return true
    },
  }

  const PasswordDescription = <DarkDescription>**Password strength/restrictions here?<br />(8+ chars, upper/lower, number, special)</DarkDescription>

  return (
    <ThemeProvider theme={ ahaTheme }>
      <Header>
        <StyledLogo size={ SVG_LOGO_HEADER_SIZE } color={ ahaTheme.colors.text.white } />
        <HeaderText>
          <h1>Registration</h1>
          <p>Complete this registration form to sign up and get<br />started or sign in with your Aim High account.</p>
        </HeaderText>
      </Header>
      <Main style={ { display: 'grid' } } $withFooter>
        <StyledSection>
          <StyledForm
            onSubmit={ handleFormSubmit }
            formHooks={ formHooks }>
            <FormTextField label='First Name' name='firstName' placeholder='Ex: John' rules={ textFieldValidation } style={ { gridColumn: 'span 1' } } dark />
            <FormTextField label='Last Name' name='lastName' placeholder='Ex: Smith' rules={ textFieldValidation } style={ { gridColumn: 'span 1' } } dark />
            <FormTextField label='Personal Email Address' name='email' placeholder={ `Ex: 'john.smith@gmail.com` } rules={ emailValidation } dark />
            <FormTextField label='Password' name='password' type='password' rules={ passwordValidation } description={ PasswordDescription } dark />
            <FormTextField label='Confirm Password' name='passwordConfirm' type='password' rules={ passwordConfirmValidation } dark />
          </StyledForm>
          <RegistrationButtons>
            <Button variant='secondary' onClick={ handleSignIn } dark>Sign in with Aim High</Button>
            <Button type='submit' onClick={ formHooks.handleSubmit(handleFormSubmit) } dark>Register</Button>
          </RegistrationButtons>
        </StyledSection>
      </Main>
      <Footer>
        <a href='#'>Privacy policy</a> | <a href='#'>Terms and Conditions</a>
      </Footer>
    </ThemeProvider>
  )
}

export default withRouter(LineOfficerRegistration)
